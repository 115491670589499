import request from "@/utils/request";

export function getNewsList (data) {
  return request({
    url: '/api/article',
    method: 'get',
    params: data
  })
}

export function getNewsDetail (id) {
  return request({
    url: '/api/article/'+id,
    method: 'get',
  })
}

export function getNewsListByPage (data) {
  return request({
    url: '/api/article/page',
    method: 'get',
    params: data
  })
}

export function getNewsListNext (data) {
  return request({
    url: '/api/article/next',
    method: 'get',
    params: data
  })
}
export function getNewsListPrev (data) {
  return request({
    url: '/api/article/prev',
    method: 'get',
    params: data
  })
}

export function getNewsView (data) {
  return request({
    url: '/api/article/view/'+data,
    method: 'get',
  })
}

export function getDict (data) {
  return request({
    url: '/api/dict',
    method: 'get',
    params: data
  })
}

export function upLoadResume (data) {
  return request({
    url: '/api/customUpload/resume',
    method: 'post',
    data: data,
  })
}

export function getPostList (data) {
  return request({
    url: '/api/recruitment/postList',
    method: 'get',
    params: data
  })
}
export function saveAgency (data) {
  return request({
    url: '/api/supplier/save',
    method: 'post',
    data: data
  })
}

export function getCategoryNum (data) {
  return request({
    url: '/api/recruitment/categoryNum',
    method: 'get',
    params: data
  })
}

// 查询服务商
export function getWholeSale (data) {
  return request({
    url: '/api/wholeSale/serviceList',
    method: 'get',
    params: data
  })
}

// 地区
export function getArea (data) {
  return request({
    url: '/api/area',
    method: 'get',
    params: data
  })
}
// 保存联系我们
export function saveContactInfo (data) {
  return request({
    url: '/api/contactInfo/save',
    method: 'post',
    data: data
  })
}
// 获取图片验证码
export function getCaptcha (data) {
  return request({
    url: '/api/captcha',
    method: 'get',
    params: data
  })
}
// 获取图片验证码
export function tryCaptcha (data) {
  return request({
    url: '/api/captcha/try',
    method: 'get',
    params: data
  })
}

export function searchNews (data) {
  return request({
    url: '/api/information/searchKeyword',
    method: 'get',
    params: data
  })
}
// 用户浏览量
export function webRecordLog (data) {
  return request({
    url: '/api/userLog/webRecordLog',
    method: 'get',
    params: data
  })
}