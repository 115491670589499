var fun = function (document, window) {
  //获取屏幕可视区域宽度
  var deviceWidth = document.body.clientWidth;
  //区分pc端和移动端，根据设计稿宽度计算根字体大小
  if (deviceWidth > 768) {
  //设计稿宽度1920px/实际字体大小100px = 19.20
    document.documentElement.style.fontSize = deviceWidth / 19.20 + 'px';
  } else {
    document.documentElement.style.fontSize = deviceWidth / 7.50 + 'px';
  }
}
fun(document, window)
window.addEventListener('resize',()=>{
  fun(document, window)
})