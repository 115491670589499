<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :hide-on-single-page="hideOnSinglePage"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [30, 50, 100, 500]
      }
    },
    hideOnSinglePage:{
      type: Boolean,
      default: true
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 3 : 5
    },
    layout: {
      type: String,
      default: 'prev, pager, next'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1
      }
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style lang='scss' scoped>
.pagination-container{
  display: flex;
  justify-content: center;
  ::v-deep{
    .el-pagination.is-background {
      display: flex;
      padding: 0;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      border: 0.01rem solid #0068B7;
      border-radius: 100%;
      color: #0068B7;
      background-color: #fff;
    }
    .el-pagination{
      .btn-prev ,.btn-next{
        .el-icon{
          font-size: 0.24rem;
        }
      }
      .btn-prev ,.btn-next,.el-pager li{
        font-size: 0.24rem;
        line-height: 0.52rem;
        box-sizing: border-box;
        color: #666666 ;
        font-family: FZLanTingHeiS-R-GB;
        font-weight: 400;
        background-color: #fff !important;
        width: 0.52rem;
        height: 0.52rem;
        text-align: center;
        margin-right: 0.3rem;
      }
    }
  }
}
</style>

