import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    meta:{
      title:"首页",
      // actMenus:"index"
    },
    component: () => import('../views/Home/index.vue'),
  },
  {
    path: '/',
    redirect: '/index',
  },
  // {
  //   path:'/demo',
  //   name:"demo",
  //   component: () => import('../views/Home/demo.vue'),
  // },
  // {
  //   path:'/404',
  //   name:"404",
  //   component: () => import('../views/Home/404.vue'),
  // },

  // 新闻中心
  {
    path: '/businessMovement',
    name: 'BusinessMovement',
    meta:{
      title:"国内动态",
      actMenus:"news",
      alive:true,
    },
    component: () => import('../views/NewsCenter/BusinessMovement/index.vue'),
  },
  {
    path: '/nationalMovement',
    name: 'NationalMovement',
    meta:{
      title:"国际动态",
      actMenus:"news",
      alive:true,
    },
    component: () => import('../views/NewsCenter/NationalMovement/index.vue'),
  },
  
  {
    path: '/BusinessDetail/:id',
    name: 'BusinessDetail',
    meta:{
      title:"企业动态详情",
      actMenus:"news"
    },
    component: () => import('../views/NewsCenter/BusinessMovement/detail.vue'),
  },

  {
    path: '/videoCenter',
    name: 'VideoCenter',
    meta:{
      title:"视频中心",
      actMenus:"news"
    },
    component: () => import('../views/NewsCenter/VideoCenter/index.vue'),
  },
  // {
  //   path: '/special',
  //   name: 'SpecialList',
  //   meta:{
  //     title:"专题专栏",
  //     actMenus:"news"
  //   },
  //   component: () => import('../views/NewsCenter/SpecialList/index.vue'),
  // },
  // {
  //   path: '/specialDetail/:id',
  //   name: 'SpecialList',
  //   meta:{
  //     title:"专题专栏详情",
  //     actMenus:"news"
  //   },
  //   component: () => import('../views/NewsCenter/SpecialList/detail.vue'),
  // },
  // {
  //   path: '/notice',
  //   name: 'NoticeList',
  //   meta:{
  //     title:"通知公告",
  //     actMenus:"news"
  //   },
  //   component: () => import('../views/NewsCenter/NoticeList/index.vue'),
  // },
  // {
  //   path: '/noticeDetail/:id',
  //   name: 'NoticeDetail',
  //   meta:{
  //     title:"通知公告详情",
  //     actMenus:"news"
  //   },
  //   component: () => import('../views/NewsCenter/NoticeList/detail.vue'),
  // },

  // 投资者关系
  {
    path: '/investorProtection',
    name: 'InvestorProtection',
    meta:{
      title:"投资者保护",
      actMenus:"InvestorProtection"
    },
    component: () => import('../views/InvestorRelation/InvestorProtection/index.vue'),
  },
  {
    path: '/investorDetail/:id',
    name: 'InvestorProtection',
    meta:{
      title:"投资者保护详情",
      actMenus:"InvestorProtection"
    },
    component: () => import('../views/InvestorRelation/InvestorProtection/detail.vue'),
  },
  {
    path: '/periodicReport',
    name: 'PeriodicReport',
    meta:{
      title:"定期报告",
      actMenus:"InvestorProtection"
    },
    component: () => import('../views/InvestorRelation/PeriodicReport/index.vue'),
  },

  // 可持续发展
  {
    path: '/esg',
    name: 'esg',
    meta:{
      title:"ESG治理",
      actMenus:"SustainableDevelopment"
    },
    component: () => import('../views/SustainableDevelopment/ESG/index.vue'),
  },
  {
    path: '/greenDevelopment',
    name: 'greenDevelopment',
    meta:{
      title:"ESG治理",
      actMenus:"SustainableDevelopment"
    },
    component: () => import('../views/SustainableDevelopment/GreenDevelopment/index.vue'),
  },
  {
    path: '/environmental',
    name: 'Environmental',
    meta:{
      title:"环境公开",
      actMenus:"SustainableDevelopment"
    },
    component: () => import('../views/SustainableDevelopment/Environmental/index.vue'),
  },
  {
    path: '/informationSecurity',
    name: 'InformationSecurity',
    meta:{
      title:"信息安全",
      actMenus:"SustainableDevelopment"
    },
    component: () => import('../views/SustainableDevelopment/InformationSecurity/index.vue'),
  },
// 人才招聘
  {
    path: '/talent',
    name: 'Talent',
    meta:{
      title:"人才招聘",
      actMenus:"Join",
      alive:true,
    },
    component: () => import('../views/Join/Talent/index.vue'),
  },

  {
    path: '/talent/social',
    name: 'TalentSocial',
    meta:{
      title:"社会招聘",
      actMenus:"Join"
    },
    component: () => import('../views/Join/Talent/social/index.vue'),
  },
  {
    path: '/talent/school',
    name: 'TalentSchool',
    meta:{
      title:"校园招聘",
      actMenus:"Join"
    },
    component: () => import('../views/Join/Talent/school/index.vue'),
  },
  {
    path: '/recruitDetail/:id',
    name: 'RecruitDetail',
    meta:{
      title:"校招公告详情",
      actMenus:"Join"
    },
    component: () => import('../views/Join/Talent/school/detail.vue'),
  },

  {
    path: '/talentDetail/:id',
    name: 'talentDetail',
    meta:{
      title:"岗位信息详情",
      actMenus:"Join"
    },
    component: () => import('../views/Join/Talent/detail.vue'),
  },

  // 全球市场
  {
    path: '/international',
    name: 'International',
    meta:{
      title:"国际市场",
      actMenus:"GlobalMarket"
    },
    component: () => import('../views/GlobalMarket/International/index.vue'),
  },
  {
    path: '/domestic',
    name: 'Domestic',
    meta:{
      title:"国内市场",
      actMenus:"GlobalMarket"
    },
    component: () => import('../views/GlobalMarket/Domestic/index.vue'),
  },
  // {
  //   path: '/supporting',
  //   name: 'Supporting',
  //   meta:{
  //     title:"配套市场",
  //     actMenus:"GlobalMarket"
  //   },
  //   component: () => import('../views/GlobalMarket/Supporting/index.vue'),
  // },
  // {
  //   path: '/eCommerce',
  //   name: 'ECommerce',
  //   meta:{
  //     title:"电商业务",
  //     actMenus:"GlobalMarket"
  //   },
  //   component: () => import('../views/GlobalMarket/ECommerce/index.vue'),
  // },

  // 产品方案
  {
    path: '/lowerPlumbic',
    name: 'lowerPlumbicAcid',
    meta:{
      title:"低压铅酸",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/lowerPlumbicAcid/index.vue'),
  },
  {
    path: '/plumbicRecovery',
    name: 'plumbicAcidRecovery',
    meta:{
      title:"铅酸回收",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/plumbicAcidRecovery/index.vue'),
  },
  {
    path: '/lowLithium',
    name: 'lowLithium',
    meta:{
      title:"低压锂电",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/lowLithium/index.vue'),
  },
  {
    path: '/lithiumRecovery',
    name: 'lithiumRecovery',
    meta:{
      title:"锂电再生",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/lithiumRecovery/index.vue'),
  },
  // {
  //   path: '/energyBusiness',
  //   name: 'energyBusiness',
  //   meta:{
  //     title:"储能业务",
  //     actMenus:"productProgram"
  //   },
  //   component: () => import('../views/productProgram/energyBusiness/index.vue'),
  // },
  {
    path: '/intelligentLogistics',
    name: 'intelligentLogistics',
    meta:{
      title:"智慧物流",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/intelligentLogistics/index.vue'),
  },
  {
    path: '/userAnswer',
    name: 'userAnswer',
    meta:{
      title:"用户答疑",
      actMenus:"productProgram"
    },
    component: () => import('../views/productProgram/userAnswer/index.vue'),
  },

  // 关于骆驼
  {
    path: '/honorary',
    name: 'Honorary',
    meta:{
      title:"荣誉资质",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/Honorary/index.vue'),
  },
  {
    path: '/history',
    name: 'History',
    meta:{
      title:"发展历程",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/History/index.vue'),
  },
  {
    path: '/culture',
    name: 'Culture',
    meta:{
      title:"企业文化",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/CorporateCulture/index.vue'),
  },
  {
    path: '/groupProfile',
    name: 'GroupProfile',
    meta:{
      title:"集团简介",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/GroupProfile/index.vue'),
  },
  {
    path: '/strategy',
    name: 'Strategy',
    meta:{
      title:"发展战略",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/Strategy/index.vue'),
  },
  {
    path: '/positioning',
    name: 'Positioning',
    meta:{
      title:"品牌定位",
      actMenus:"camel"
    },
    component: () => import('../views/AboutCamel/Positioning/index.vue'),
  },
  // 研发制造
  {
    path: '/intelligent',
    name: 'Intelligent',
    meta:{
      title:"智能制造",
      actMenus:"research"
    },
    component: () => import('../views/Research/IntelligentManufacturing/index.vue'),
  },
  {
    path: '/certification',
    name: 'Certification',
    meta:{
      title:"体系认证",
      actMenus:"research"
    },
    component: () => import('../views/Research/SystemCertification/index.vue'),
  },
  // {
  //   path: '/innovative',
  //   name: 'Innovative',
  //   meta:{
  //     title:"创新研发",
  //     actMenus:"research"
  //   },
  //   component: () => import('../views/Research/Innovative/index.vue'),
  // },
  // 联系我们
  {
    path: '/connect',
    name: 'Connect',
    meta:{
      title:"联系我们",
      actMenus:"connectUs"
    },
    component: () => import('../views/AboutCamel/Connect/index.vue'),
  },
  {
    path: '/purchase',
    name: 'Purchase',
    meta:{
      title:"供应链平台",
      actMenus:"connectUs"
    },
    component: () => import('../views/Join/Purchase/index.vue'),
  },
  {
    path: '/agency',
    name: 'Agency',
    meta:{
      title:"经销商合作",
      actMenus:"connectUs"
    },
    component: () => import('../views/Join/Agency/index.vue'),
  },
  {
    path: '/statement',
    name: 'Statement',
    meta:{
      title:"",
    },
    component: () => import('../views/Statement/index.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    meta:{
      title:"查询",
    },
    component: () => import('../views/Search/index.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/index',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,position){
    if(position){
      return position
    }else{
      return {
        x:0,
        y:0
      }
    }
    
  }
})
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
