<template>
  <div class="fixBtn" :class="[scrollTop>0?'fixBtnAct':'']">
    <div class="btnTop" @click="scrollTops"></div>
    <div class="btnBottom" @click="scrollBottom"></div>
    <!-- <div class="backTop"  @click="toTop"></div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      scrollTop:0,
    }
  },
  mounted(){
    this.handleScroll()
    window.addEventListener('scroll',this.handleScroll)

  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll(){
      this.scrollTop=document.documentElement.scrollTop || document.body.scrollTop;
    },
    toTop(){
      window.scrollTo({
        top:0,
        behavior:"smooth"
      })
    },
    scrollTops(){
      let scrollTop=document.documentElement.scrollTop || document.body.scrollTop;
      let height =scrollTop - window.innerHeight/2
      window.scrollTo({
        top:height,
        behavior:"smooth"
      })
    },
    scrollBottom(){
      let scrollTop=document.documentElement.scrollTop || document.body.scrollTop;
      let height =scrollTop+ window.innerHeight/2
      window.scrollTo({
        top:height,
        behavior:"smooth"
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.fixBtn{
  position: fixed;
  right: 0.40rem;
  transition: all  0.3s ease-in-out;
  z-index: 98;
  bottom: -2rem;
}

.btnTop{
  width: 0.5rem;
  height: 0.5rem;
  background: url('./img/top.webp') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.btnBottom{
  margin:0.2rem  0;
  width: 0.5rem;
  height: 0.5rem;
  background: url('./img/bottom.webp') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.fixBtnAct{
  bottom: 0.4rem;
}
@media screen and (max-width: 767px){
  .fixBtn{
    right: 0.2rem !important;
  }
}
</style>