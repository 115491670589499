import axios from 'axios'

// import store from '@/store'
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/cms',//测试环境
  // 超时
  timeout: 10000
})
service.interceptors.request.use(config => {
  if (config.method == 'get') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  } else if (config.method == 'post') {
    if (config.url === '/api/customUpload/resume') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }else{
      config.headers['Content-Type'] = 'application/json'
    }
  }
  return config
}, error => {
  Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(res => {
  return res.data
}, error => {
  // console.log(error, 'e')
  return Promise.reject(error)
  // let code = response.code
})

export default service
