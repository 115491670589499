<template>
  <div id="app">
    <keep-alive v-if="$route.meta.alive">
      <router-view ></router-view>
    </keep-alive>
    <router-view v-else></router-view>
    <BackTop/>
  </div>
</template>
<script>
import BackTop from '@/components/BackTop/index.vue';
export default {
  components:{
    BackTop
  }
}
</script>