export function msg(message, type) {
  const messageDom = document.getElementsByClassName('el-message')[0]
  if (messageDom === undefined) {
    this.$message({
      // showClose: true,
      message,
      type,
      duration:2000,
    })
  }
}
